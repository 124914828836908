import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Md5} from 'md5-typescript';
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {

  readonly rootUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  constructor(private http: HttpClient) { }

  userAuthentication(email: string, password: string, codaz: number) {
    const pwdMd5 = Md5.init(password);
    password = pwdMd5;
    return new Promise(resolve => {
      this.http.get(this.rootUrl + 'login/' + codaz + '/' + email + '/' + password ).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });

  }

  token(id) {
    return new Promise(resolve => {
      this.http.get(this.rootUrl + 'token/' + id ).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getUser(id) {
    return new Promise(resolve => {
      this.http.get(this.rootUrl + 'Operatori/' + id + '/0' ).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }



}
