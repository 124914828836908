import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './../services/user.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  userid: any;
  userdata: any;

  nomeutente = '';


  constructor(private uServ: UserService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');
    // ******
    this.uServ.getUser(this.userid).then(data => {
      this.userdata = data;
      this.nomeutente = this.userdata.nome;
    });
  }


  Logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

}
