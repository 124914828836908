import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Observable} from 'Rxjs/rx';

import { AccessiService } from './../../services/accessi.service';

import { Accessi } from './../../model/accessi';

@Component({
  selector: 'app-wdgriacc',
  templateUrl: './wdgriacc.component.html',
  styleUrls: ['./wdgriacc.component.css']
})
export class WdgriaccComponent implements OnInit {

  r = {id: 0, cognomeNome: '', referente: '', entrata: '',  uscita: '', codvisit: 0, badge: 0};

  cols = [ 'cognomeNome', 'referente', 'entrata', 'uscita', 'checkinout', 'badge', 'buttons'];
  ds: MatTableDataSource<Accessi>;

  public rl: Accessi[] = [];

  codaz: any;

  res: any;

  exitVisitor: any;

  italyTime: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public aServ: AccessiService, public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.italyTime = this.calculateTime();
  }

  ngOnInit() {
    this.codaz = localStorage.getItem('codaz');


    this.LoadData(this.rl);
    this.ds = new MatTableDataSource(this.rl);
    // ******
    this.ds.paginator = this.paginator;
    this.ds.sort = this.sort;
    // ******
    this.ReloadData();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();         // Remove whitespace
    filterValue = filterValue.toLowerCase();  // Datasource defaults to lowercase matches
    this.ds.filter = filterValue;
 }

 AccessiData(d): any {
       return {
        id : d.id,
        codvisit: d.codvisit,
        codaz: d.codaz,
        cognomeNome: d.cognomeNome,
        referente: d.referente,
        entrata: d.entrata,
        uscita: d.uscita,
        badge: d.numeroBadge
      };
  }

  LoadData(c) {
    this.aServ.getAccessiAttivi(this.codaz).then(data => {
      this.res = data;
      for (const rs of this.res ) {
        c.push(this.AccessiData(rs));
      }
    });
  }

  RefreshData() {
    Observable.interval(12000).subscribe(() => {
      this.ReloadData();
    });
  }


  ReloadData() {
    this.aServ.getAccessiAttivi(this.codaz).then(data => {
      this.res = data;
      this.rl = [];
      for (const rs of this.res ) {
        this.rl.push(this.AccessiData(rs));
      }
      this.ds = new MatTableDataSource(this.rl);
      this.ds.paginator = this.paginator;
      this.ds.sort = this.sort;
    });
  }


  ExitVisitor(id) {
    this.openDialog(id);
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(WdgriaccExitComponent, {
      width: '250px',
      data: { confirm: 'S' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.exitVisitor = result;
      if (this.exitVisitor === 'S') {
        const ac = JSON.stringify({'Uscita': this.italyTime});
        this.aServ.updateAccessi(id, ac ).then(data => {
          if (data === 1) {
            this.snackBar.open('Uscita visitatore, confermata!', 'OK', {
              duration: 3000
            });
            this.ReloadData();
          }
        });
      }
    });
  }

  calculateTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }

}


@Component({
  selector: 'app-wdgriacc-exit-dialog',
  templateUrl: './wdgriacc-exit-dialog.html',
})
export class WdgriaccExitComponent {

  constructor(
    public dialogRef: MatDialogRef<WdgriaccExitComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
