import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admlogin',
  templateUrl: './admlogin.component.html',
  styleUrls: ['./admlogin.component.css']
})
export class AdmloginComponent implements OnInit {

  lg = {username: '', password: ''};

  constructor(private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
  }


  Login() {
    console.log(this.lg);
    if (this.lg.username === 'mts@Admin' && this.lg.password === 'Mts.2018.Mts') {
      this.router.navigateByUrl('/admin/dash');
    } else {
      this.snackBar.open('Username e/o Password Errati', 'OK', {
        duration: 5000
      });
    }

  }

}
