import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';


@Injectable()
export class AziendaService {

    apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    constructor(public http: HttpClient) { }

    // ********************************
    getAllAzienda() {
        return new Promise(resolve => {
            this.http.get(this.apiUrl + 'Azienda/').subscribe(data => {
            resolve(data);
            }, err => {
            console.log(err);
            });
        });
    }

    getAzienda(id) {
        return new Promise(resolve => {
            this.http.get(this.apiUrl + 'Azienda/' + id).subscribe(data => {
            resolve(data);
            }, err => {
            console.log(err);
            });
        });
    }

    createAzienda(par) {
        return new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + 'Azienda', par, this.httpOptions)
                .subscribe(res => {
                resolve(res);
                }, (err) => {
                reject(err);
                });
            });
    }

    updateAzienda(id, par) {
        return new Promise((resolve, reject) => {
            this.http.put(this.apiUrl + 'Azienda/' + id, par, this.httpOptions)
                .subscribe(res => {
                resolve(res);
                }, (err) => {
                reject(err);
                });
            });
    }

    deleteAzienda(id) {
        return new Promise(resolve => {
            this.http.delete(this.apiUrl + 'Azienda/' + id).subscribe(data => {
            resolve(data);
            }, err => {
            console.log(err);
            });
        });
    }

    getParIva(piva) {
      return new Promise(resolve => {
        this.http.get(this.apiUrl + 'AziendaPariva/' + piva).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }


}
