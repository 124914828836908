import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {MatSnackBar} from '@angular/material';
import { UserService } from './../services/user.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userid: any;
  userdata: any;

  nomeutente = '';
  desruoloutente = '';

  constructor(private uServ: UserService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');

    if (this.userid === null) {
      this.router.navigateByUrl('/login');
    }

    this.uServ.getUser(this.userid).then(data => {
      this.userdata = data;
      console.log(this.userdata)
      this.nomeutente = this.userdata.Nome;
      this.desruoloutente = this.userdata.Desruolo;
    });


  }




}
