import { Component, OnInit } from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import { DloperatoriComponent } from './../dialog/dloperatori/dloperatori.component';

@Component({
  selector: 'app-operat',
  templateUrl: './operat.component.html',
  styleUrls: ['./operat.component.css']
})
export class OperatComponent implements OnInit {

  operat: any;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  NewOperat(id) {
    this.openDialog(id);
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(DloperatoriComponent, {
      width: '540px',
      data: { newVis: id }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (typeof result === 'undefined') {
        result = 0 ;
      }

      this.operat = result;
      if (this.operat !== 0) {
        this.snackBar.open('Operatore salvato con successo!', 'OK', {
          duration: 3000
        });
      }

    });
  }

}
