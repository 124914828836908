import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class AccessiService {

  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(public http: HttpClient) { }

  // ********************************
  getAllAccessi(codaz: number) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'Accessi/' + codaz).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }


  getAccessi(id, codaz) {
    return new Promise(resolve => {
        this.http.get(this.apiUrl + 'Accessi/' + id + '/' + codaz).subscribe(data => {
        resolve(data);
        }, err => {
        console.log(err);
        });
    });
  }


  getEntrataUscita(id, codaz) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'EntrataUscita/' + id + '/' + codaz).subscribe(data => {
      resolve(data);
      }, err => {
      console.log(err);
      });
    });
  }

  getVisitatoriPresenti(codaz) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'VisitatoriPresenti/' + codaz).subscribe(data => {
      resolve(data);
      }, err => {
      console.log(err);
      });
    });
  }

  getAccessiAttivi(codaz) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + '/AccessiAttivi/' + codaz).subscribe(data => {
      resolve(data);
      }, err => {
      console.log(err);
      });
    });
  }

  getAccessiBadge(codaz, numbadge ) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + '/AccessiBadge/' + codaz + '/' + numbadge).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }




  createAccessi(par) {
    console.log(par);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + '/Accessi/', par, this.httpOptions)
          .subscribe(res => {
          resolve(res);
          }, (err) => {
          reject(err);
          });
      });
  }

  updateAccessi(id, par) {
    return new Promise((resolve, reject) => {
      this.http.put(this.apiUrl + '/Accessi/' + id, par, this.httpOptions)
          .subscribe(res => {
          resolve(res);
          }, (err) => {
          reject(err);
          });
      });
  }

}
