import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  activeLinkAccessi = false;

  constructor(public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
  }



  private onLinkClick(event, routeUrl: string) {
    if (!this.router.isActive(routeUrl, false)) {
      console.log(routeUrl);
    }

  }


}
