
import { UserregComponent } from './../userreg/userreg.component';
import { HomeComponent } from './../home/home.component';
import { DashboardComponent } from './../dashboard/dashboard.component';
import { AnagraComponent } from './../anagra/anagra.component';
import { LoginComponent } from './../login/login.component';
import { OperatComponent } from './../operat/operat.component';
// rappruppiamo le rotte in un solo modulo

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';   /*moduli per le rotte */
import { DlanagraComponent } from './../dialog/dlanagra/dlanagra.component';
import { DloperatoriComponent } from './../dialog/dloperatori/dloperatori.component';
import { LostpwdComponent } from '../lostpwd/lostpwd.component';
import { RuoliComponent } from '../ruoli/ruoli.component';
import { AziendaComponent } from '../azienda/azienda.component';
import { StataccessiComponent } from '../stataccessi/stataccessi.component';
import { InsertcodeComponent } from '../insertcode/insertcode.component';
import {EndvisitComponent} from '../endvisit/endvisit.component';
import {PrintbadgeComponent} from '../printbadge/printbadge.component';
import { AdmloginComponent } from '../admin/admlogin/admlogin.component';
import { AdmdashComponent } from '../admin/admdash/admdash.component';


/* definizione delle rotte*/
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {path: 'home', component: HomeComponent},
  {path: 'userreg', component: UserregComponent},
  {path: 'login', component: LoginComponent},
  {path: 'lostpwd', component: LostpwdComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'anagra', component: AnagraComponent},
  {path: 'dlanagra', component: DlanagraComponent},
  {path: 'operat', component: OperatComponent},
  {path: 'dloperatori', component: DloperatoriComponent},
  {path: 'ruoli', component: RuoliComponent},
  {path: 'azienda', component: AziendaComponent},
  {path: 'statacc', component: StataccessiComponent },
  {path: 'inscode', component: InsertcodeComponent },
  {path: 'endvis', component: EndvisitComponent },
  {path: 'printb', component: PrintbadgeComponent},
  {path: 'admin/login', component: AdmloginComponent},
  {path: 'admin/dash', component: AdmdashComponent},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ],
  // providers : [RouteGuardService],
  providers : [],
  declarations: []
})
export class RoutingModuleModule { }
