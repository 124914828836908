import { Component, OnInit } from '@angular/core';

import { AziendaService } from './../../services/azienda.service';

@Component({
  selector: 'app-wdazienda',
  templateUrl: './wdazienda.component.html',
  styleUrls: ['./wdazienda.component.css']
})
export class WdaziendaComponent implements OnInit {

  a = {ragsoc: '', pariva: '', mail_smtp: '', mail_port: '', mail_address: '', mail_username: '', mail_password: ''};
  codaz: any;
  az: any;

  constructor(public aServe: AziendaService) {
    this.codaz = localStorage.getItem('codaz');
  }

  ngOnInit() {
    this.aServe.getAzienda(this.codaz).then(data => {
      this.az = data;
      this.pushData(this.az);
    });
  }


  pushData(az) {
    console.log(az);
    this.a.ragsoc = az.ragsoc;
    this.a.pariva = az.pariva;
    this.a.mail_smtp = az.mail_Smtp;
    this.a.mail_port = az.mail_Port;
    this.a.mail_address = az.mail_Address;
    this.a.mail_username = az.mail_UserName;
    this.a.mail_password = az.mail_Password;
  }
}
