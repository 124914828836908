import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { OperatoriService } from '../../services/operatori.service';
 import { ActivatedRoute, Router } from '@angular/router';
 import { DloperatoriComponent } from '../../dialog/dloperatori/dloperatori.component';
 import {MatDialog} from '@angular/material';
import { Operatore } from '../../model/operatore';

@Component({
  selector: 'app-wdoperatori',
  templateUrl: './wdoperatori.component.html',
  styleUrls: ['./wdoperatori.component.css']
})
export class WdoperatoriComponent implements OnInit {

  codaz: any;

  res: any;

  cols = ['nome', 'email', 'desruolo', 'abilitato', 'buttons'];

  ds: MatTableDataSource<Operatore>;

  public op: Operatore[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private oServ: OperatoriService, public dialog: MatDialog) {
    this.codaz = localStorage.getItem('codaz');
  }

  ngOnInit() {
    this.LoadData(this.op);

    this.ds = new MatTableDataSource(this.op);
    // ******
    this.ds.paginator = this.paginator;
    this.ds.sort = this.sort;
    // ******
    this.RefreshData();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();         // Remove whitespace
    filterValue = filterValue.toLowerCase();  // Datasource defaults to lowercase matches
    this.ds.filter = filterValue;
  }

  LoadData(c) {

    this.oServ.getAllOperatori(this.codaz).then(data => {
      this.res = data;
      console.log(this.res);
      for (const rs of this.res ) {
        c.push(this.OperatData(rs));
      }
    });
  }

  OperatData(d): Operatore {
    return {
      id: d.Id,
      codaz: d.Codaz,
      nome: d.Nome,
      ruolo: d.Ruolo,
      email: d.Email,
      password: d.Password,
      livello: d.Livello,
      abilitato: d.Abilitato,
      desruolo: d.Desruolo
    };
  }

  RefreshData() {
    this.oServ.getAllOperatori(this.codaz).then(data => {
      this.res = data;
      this.op = [];
      for (const rs of this.res ) {
        this.op.push(this.OperatData(rs));
      }
      this.ds = new MatTableDataSource(this.op);
      this.ds.paginator = this.paginator;
      this.ds.sort = this.sort;
    });
  }

  NewOperat(id) {
    this.openDialog(id);
  }


  openDialog(id): void {
    const dialogRef = this.dialog.open(DloperatoriComponent, {
      width: '540px',
      data: { newVis: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.exitVisitor = result;
      /*
      if (this.exitVisitor === 'S') {
        const ac = JSON.stringify({'Uscita': this.italyTime});
        this.aServ.updateAccessi(id, ac ).then(data => {
          if (data === 1) {
            this.snackBar.open('Uscita visitatore, confermata!', 'OK', {
              duration: 3000
            });
            this.RefreshData();
          }
        });
      }
      */
    });
  }

}
