import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()

export class OperatoriService {
  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  constructor(public http: HttpClient) { }

    // ********************************
    getAllOperatori(codaz: number) {
      return new Promise(resolve => {
        this.http.get(this.apiUrl + 'Operatori/' + codaz).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }

    getOperatori(id: number, codaz: number) {
      return new Promise(resolve => {
        this.http.get(this.apiUrl + 'Operatori/' + id + '/' + codaz).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }

    createOperatori(par) {
      return new Promise((resolve, reject) => {
          this.http.post(this.apiUrl + 'Operatori', par, this.httpOptions)
            .subscribe(res => {
              resolve(res);
            }, (err) => {
              reject(err);
            });
        });
    }

    updateOperatori(codaz: number, id: number, par) {
      return new Promise((resolve, reject) => {
          this.http.put(this.apiUrl + 'Operatori/' + codaz.toString() + '/' + id.toString(), par, this.httpOptions)
            .subscribe(res => {
              resolve(res);
            }, (err) => {
              reject(err);
            });
        });
    }

    deleteOperatori(  id: number, codaz: number) {
      return new Promise(resolve => {
        this.http.delete(this.apiUrl + 'Operatori/' + codaz.toString() + '/' + id.toString()).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }

}
