import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatoriService } from '../services/operatori.service';
import { VisitlistService } from './../services/visitlist.service';
import { AccessiService } from './../services/accessi.service';

import {MatSnackBar} from '@angular/material';


import { IpService } from './../services/ip.service';
import { OpzioniService} from '../services/opzioni.service';

@Component({
  selector: 'app-userreg',
  templateUrl: './userreg.component.html',
  styleUrls: ['./userreg.component.css']
})
export class UserregComponent implements OnInit {

  vis: any;
  remoteIp: any;
  imgPersPath: string;
  imgPath: string;

  imgPersAz: string;
  codaz: any;

  IdAzienda: any;
  OperatData: any;

  ur = {codaz: 0, nome: '', cognome: '', email: '', descrizione_az: '', id: 0, referente: '', privacy: 'N', telefono: ''};

  selectedvalue: string;
  checked = false;

  idVisit: any;

  checkOperat = true;
  checkPrivacy = true;

  italyTime: string;

  visit: any;

  idAz = 0;

  Opzioni: any;

  constructor(public ipServ: IpService, public oServe: OperatoriService, public vServe: VisitlistService, public acServe: AccessiService,
              public vServ: VisitlistService, public opzServe: OpzioniService,
              private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar) {
                this.idAz = 3;
                this.italyTime = this.calculateTime();
              }

  ngOnInit() {
    this.imgPath = '../assets/img/logo.png';
    this.ipServ.getRemoteIp().then(data => {
      this.remoteIp = data;
      this.remoteIp = this.remoteIp.ip.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');

      this.ipServ.getCheckRemoteIp(this.remoteIp.ip).then(d => {
        this.IdAzienda = d;
        if (this.IdAzienda !== 0) {
          this.imgPersPath = '../assets/img/' + this.IdAzienda.toString() + '/logo.png';
          this.imgPersAz = '../assets/img/' + this.IdAzienda.toString() + '/logo_gv.png';
        }
      });
    });

    this.LoadComboOpertat(this.idAz);
  }

  SignUp() {
    if (this.checked === false) {
      this.checkPrivacy = false;
      // *****
      this.snackBar.open('Accettazione privacy non selezionata', 'OK', {
        duration: 3000
      });
    } else {
      this.checkPrivacy = true;
    }


    if (parseInt(this.selectedvalue, 10) === 0) {
      this.checkOperat = false;
      // *****
      this.snackBar.open('Riferimento operatore non selezionata', 'OK', {
        duration: 3000
      });
    } else {
      this.checkOperat = true;
    }

    if (!(this.checkPrivacy && this.checkOperat)) {
      return;
    }
    this.ur.codaz = this.IdAzienda;
    this.ur.id = parseInt(this.selectedvalue, 10);
    if (this.checked === true) {
      this.ur.privacy = 'S';
    }
    const d = JSON.stringify({
      nome: this.ur.nome,
      cognome: this.ur.cognome,
      codaz: this.ur.codaz,
      email: this.ur.email,
      descrizione_az: this.ur.descrizione_az,
      telefono: this.ur.telefono,
      id: this.ur.id,
      referente: this.ur.referente,
      privacy: this.ur.privacy
    });
    this.vServe.createVisitatori(d).then(data => {
      this.idVisit = data;
      if (this.idVisit !== 0) {
        const dAc = JSON.stringify({
          codvisit: this.idVisit,
          codaz: this.ur.codaz,
          entrata: this.italyTime,
          idreferente: this.ur.id,
          numeroBadge: '0'
        });
        this.acServe.createAccessi(dAc).then(dt => {
          if (dt !== 0) {
            localStorage.setItem('idVisit', this.idVisit);
            this.opzServe.getAttivazioneBadge(this.IdAzienda).then(da => {
              this.Opzioni = da;
              if (this.Opzioni.valore === 'S') {
                this.router.navigate(['/printb']);
              } else {
                this.router.navigate(['/home']);
              }
            })
            this.snackBar.open('Registrazione effettuata con successo! E´ stato inviato un messaggio al referente in azienda', 'OK', {
              duration: 15000
            });
          }
        });
      }
    });
  }

  LoadComboOpertat(id) {
    this.oServe.getAllOperatori(id).then(data => {
      this.OperatData = data;
    });
  }

  calculateTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }

}
