import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';

import { RuoliService } from './../../services/ruoli.service';

import {Ruoli} from '../../model/ruoli';

import { DlruoliComponent } from './../../dialog/dlruoli/dlruoli.component';





@Component({
  selector: 'app-wdruoli',
  templateUrl: './wdruoli.component.html',
  styleUrls: ['./wdruoli.component.css']
})
export class WdruoliComponent implements OnInit {

  codaz: any;

  res: any;

  cols = ['descr'];

  ds: MatTableDataSource<Ruoli>;

  public rl: Ruoli[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public rServe: RuoliService, public dialog: MatDialog, ) {
    this.codaz = localStorage.getItem('codaz');
  }

  ngOnInit() {
    this.LoadData(this.rl);
    this.ds = new MatTableDataSource(this.rl);
    // ******
    this.ds.paginator = this.paginator;
    this.ds.sort = this.sort;
    // ******
    this.RefreshData();
  }

  LoadData(r) {
    this.rServe.getAllRuoli().then(data => {
      this.res = data;
      console.log(this.res);
      for (const rr of this.res ) {
        r.push(this.RuoliData(rr));
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();         // Remove whitespace
    filterValue = filterValue.toLowerCase();  // Datasource defaults to lowercase matches
    this.ds.filter = filterValue;
  }

  RuoliData(d): Ruoli {
    return {
      ruolo: d.Ruolo,
      descr: d.Descrizione
    };
  }

  RefreshData() {
    this.rServe.getAllRuoli().then(data => {
      this.res = data;
      this.rl = [];
      for (const rr of this.res ) {
        this.rl.push(this.RuoliData(rr));
      }
      this.ds = new MatTableDataSource(this.rl);
      this.ds.paginator = this.paginator;
      this.ds.sort = this.sort;
    });
  }


  NewRuolo(id) {
    this.openDialog(id);
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(DlruoliComponent, {
      width: '540px',
      data: { newRl: id }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
