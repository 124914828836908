import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'app-dlruoli',
  templateUrl: './dlruoli.component.html',
  styleUrls: ['./dlruoli.component.css']
})
export class DlruoliComponent implements OnInit {

  title = 'Nuovo Ruolo';

  constructor(public dialogRef: MatDialogRef<DlruoliComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public snackBar: MatSnackBar) { }


  ngOnInit() {
    this.title = 'Modifica Ruolo';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
