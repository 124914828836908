import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';

import { DlanagraComponent } from './../dialog/dlanagra/dlanagra.component';

@Component({
  selector: 'app-anagra',
  templateUrl: './anagra.component.html',
  styleUrls: ['./anagra.component.css']
})
export class AnagraComponent implements OnInit {

  userid: any;
  visitor: any;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.userid = localStorage.getItem('userid');

    if (this.userid === null) {
      this.router.navigateByUrl('/login');
    }
  }

  NewVisitor(id) {
    this.openDialog(id);
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(DlanagraComponent, {
      width: '540px',
      data: { newVis: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.visitor = result;
      if (this.visitor !== 0) {
        this.snackBar.open('Visitatore salvato con successo!', 'OK', {
          duration: 3000
        });
      }

    });
  }

}
