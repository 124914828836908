import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-endvisit',
  templateUrl: './endvisit.component.html',
  styleUrls: ['./endvisit.component.css']
})
export class EndvisitComponent implements OnInit {

  visbadge: any;

  u = {cognomeNome: '', entrata: '', uscita: '', checkInOut: '', referente: ''};

  constructor() { }

  ngOnInit() {

    this.u.cognomeNome = localStorage.getItem('cognomeNome');
    this.u.entrata = localStorage.getItem('entrata');
    this.u.uscita = localStorage.getItem('uscita');
    this.u.referente = localStorage.getItem('referente');



  }

}
