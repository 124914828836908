import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lostpwd',
  templateUrl: './lostpwd.component.html',
  styleUrls: ['./lostpwd.component.css']
})
export class LostpwdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
