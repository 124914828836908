import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';


@Injectable()
export class RuoliService {

  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(public http: HttpClient) { }

    // ********************************
    getAllRuoli() {
      return new Promise(resolve => {
        this.http.get(this.apiUrl + 'Ruoli/').subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }

    getRuoli(id) {
      return new Promise(resolve => {
        this.http.get(this.apiUrl + 'Ruoli/' + id).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }

    createRuoli(par) {
      return new Promise((resolve, reject) => {
          this.http.post(this.apiUrl + 'Ruoli', par, this.httpOptions)
            .subscribe(res => {
              resolve(res);
            }, (err) => {
              reject(err);
            });
        });
    }

    updateRuoli(id, par) {
      return new Promise((resolve, reject) => {
          this.http.put(this.apiUrl + 'Ruoli/' + id, par, this.httpOptions)
            .subscribe(res => {
              resolve(res);
            }, (err) => {
              reject(err);
            });
        });
    }

    deleteRuoli(id) {
      return new Promise(resolve => {
        this.http.delete(this.apiUrl + 'Ruoli/' + id).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }


}
