import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { RuoliService } from '../../services/ruoli.service';
import {Md5} from 'md5-typescript';
import { OperatoriService } from '../../services/operatori.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-dloperatori',
  templateUrl: './dloperatori.component.html',
  styleUrls: ['./dloperatori.component.css']
})
export class DloperatoriComponent implements OnInit {

  idAz = 0;
  newOperat = 0;

  selectedvalue: string;
  checked: boolean;
  selectedLivellovalue: string;

   checkNome = true;
   checkEmail = true;
   checkPassword = true;

   ruoliaz: any;

  op = {
    nome: '',
    email: '',
    password: '',
    id: 0,
    ruolo: 0,
    desruolo: '',
    livello: '',
    abilitato: '',
    codaz: 0 ,
    date_posted: new Date()
  };

  operData: any;

  title = 'Nuovo operatore';
    constructor(public dialogRef: MatDialogRef<DloperatoriComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public rServ: RuoliService, public oServe: OperatoriService, public snackBar: MatSnackBar) {
    this.idAz = 3;
    this.newOperat = data.newVis;
  }

      ngOnInit() {

        this.LoadComboRuoli();


        if (this.newOperat !== 0) {
          this.LoadUserData(this.idAz, this.newOperat);
          this.title = 'Modifica Visitatore';
        }

      }

      onNoClick(): void {
        this.dialogRef.close();
      }

      LoadComboRuoli() {
        this.rServ.getAllRuoli().then(data => {
          this.ruoliaz = data;
        });
      }

      LoadUserData(codaz, id) {
        this.oServe.getOperatori(id, codaz).then(data => {
          this.operData = data;

          this.PushFormData(this.operData);

        });
      }

      PushFormData(v) {
          this.op.codaz = v.codaz;
          this.op.nome = v.nome;
          this.op.password = v.password;
          this.op.email = v.email;
          this.op.id = v.id;
          this.selectedvalue = v.ruolo;
          this.selectedLivellovalue = v.livello;
          if (v.abilitato === 'S') {
            this.checked = true;
          } else {
            this.checked = false;
          }
      }

      SaveOperat() {

        // controllo campi obbligatori
          const strongRegexPwd = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{5,})');
          const strongRegNome = new RegExp('[a-zA-Z\s]*');
          const strongRegEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
        // nome

          if (this.op.nome.length < 3 ) {
            this.checkNome = false;
            // *****
            this.snackBar.open('Attenzione: il Nome deve contenere almeno 3 caratteri', 'OK', {
              duration: 3000
            });
            return;
          } else {
            this.checkNome = true;
          }
          // email
          if (this.op.email.length === 0) {
            this.checkEmail = false;
            // *****
            this.snackBar.open('Attenzione: Email obbligatoria', 'OK', {
              duration: 3000
            });
            return;
          } else {
            this.checkEmail = true;
          }
        /* if (strongRegEmail.test(this.op.email)) {
            this.checkEmail = false;
            // *****
            this.snackBar.open('Attenzione: Email non correttamente.', 'OK', {
              duration: 3000
            });
            return;
          } else {
            this.checkEmail = true;
          } */
          // password
          if (this.op.password.length < 5 ) {
            this.checkPassword = false;
            // *****
            this.snackBar.open('Attenzione: la Password deve contenere almeno 5 caratteri', 'OK', {
              duration: 3000
            });
          } else {
            this.checkPassword = true;
          }
          /* if (strongRegexPwd.test(this.op.password)) {
            this.checkPassword = false;
            // *****
            this.snackBar.open('La password deve contenere caratteri minuscoli, maiuscoli, speciali (!@#\$%\^&\*)', 'OK', {
              duration: 3000
            });
          } else {
            this.checkPassword = true;
          } */

          if (this.checkNome && this.checkPassword && this.checkEmail) {

            this.op.ruolo =  parseInt(this.selectedvalue, 10);
            if (this.checked) {
              this.op.abilitato = 'S';
            } else {
              this.op.abilitato = 'N';
            }
            if (typeof this.selectedLivellovalue === 'undefined' ) {
              this.selectedLivellovalue = 'U';
            }
            this.op.livello =  this.selectedLivellovalue;

            if (this.newOperat === 0) {
              const pwdMd5 = Md5.init(this.op.password);
              this.op.password = pwdMd5;
            }
            if (this.newOperat === 0) {
              this.op.codaz = this.idAz;
            }
            const d = JSON.stringify({
              nome: this.op.nome,
              codaz: this.op.codaz,
              email: this.op.email,
              password: this.op.password,
              ruolo:  this.op.ruolo,
              abilitato: this.op.abilitato,
              livello: this.op.livello
            });

            if (this.newOperat === 0) {
              this.createOperatori(d);
            } else {
              this.updateOperatori(d);
            }

            this.dialogRef.close();
          }
      }

      updateOperatori(j) {
        this.oServe.updateOperatori(this.idAz, this.newOperat, j ).then(data => {
          this.newOperat = parseInt(data.toString(), 10);
        });
      }

      createOperatori(j) {
        this.oServe.createOperatori(j).then(data => {
          this.newOperat = parseInt(data.toString(), 10);
        });
      }

}
