import { Component, OnInit, Injectable } from '@angular/core';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatSnackBar, MatDialog} from '@angular/material';
import { Router } from '@angular/router';
import { Ng2CsvService } from 'ng2csv';

import { OperatoriService } from '../services/operatori.service';
import { StataccessiService } from '../services/stataccessi.service';



export class AppDateAdapter extends NativeDateAdapter {

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();



      return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
    } else if (displayFormat === 'inputMonth') {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return  this._to2digit(month) + '/' + year;
    } else {
      return date.toDateString();
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    // monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearLabel: 'inputMonth',
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};


@Component({
  selector: 'app-stataccessi',
  templateUrl: './stataccessi.component.html',
  styleUrls: ['./stataccessi.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class StataccessiComponent implements OnInit {

  codaz: any;
  userid: any;

  selectedFromDate: any;
  selectedToDate: any;
  selectedvalue: string;

  OperatData: any;
  ListData: any;

  constructor(public oServe: OperatoriService, public sServe: StataccessiService, public dialog: MatDialog,
              public snackBar: MatSnackBar, private router: Router, private ng2Csv: Ng2CsvService) {
  }

  ngOnInit() {
    this.codaz = localStorage.getItem('codaz');
    this.userid = localStorage.getItem('userid');

    if (this.userid === null) {
      this.router.navigateByUrl('/login');
    }

    this.LoadComboOpertat(this.codaz);
  }

  LoadComboOpertat(id) {
    this.oServe.getAllOperatori(id).then(data => {
      this.OperatData = data;

      console.log(this.OperatData)
    });
  }


  GetResult() {
    if (this.selectedFromDate === undefined) {
      this.snackBar.open('Da data - Non Selezionato', 'OK', {
        duration: 3000
      });
    } else {
      if (this.selectedToDate === undefined) {
        this.snackBar.open('A data - Non Selezionato', 'OK', {
          duration: 3000
        });
      } else {
        if (this.selectedvalue === undefined) {
          this.snackBar.open('Referente - Non Selezionato', 'OK', {
            duration: 3000
          });
        } else {
          const fromDt = this.calculateTime(this.selectedFromDate);
          const toDt = this.calculateTime(this.selectedToDate);
          // ******
          this.sServe.getAllStatAccessi(this.codaz, fromDt, toDt, this.selectedvalue ).then(data => {
            this.ListData = data;

          });
        }
      }
    }
  }

  ExportCsv(): void {
    this.ng2Csv.download(this.ListData, 'StatisticheAccessi.csv');
  }

  calculateTime(dt) {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(dt - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }




}


