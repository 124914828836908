import { Component, OnInit } from '@angular/core';
import { VisitlistService } from './../services/visitlist.service';
import { AccessiService } from './../services/accessi.service';
import { IpService } from './../services/ip.service';

@Component({
  selector: 'app-printbadge',
  templateUrl: './printbadge.component.html',
  styleUrls: ['./printbadge.component.css']
})
export class PrintbadgeComponent implements OnInit {

  remoteIp: any;
  IdAzienda: any;
  IdVisit : any;
  VisitDataBadge: any;
  AccessDatabadge: any;
  pad = '0000000';

  // Campi badge ***********************
  CodVisit = '';
  CognomeNome = '';
  AziendaRifer = '';
  Operatore = '';
  DataOraVisit = '';

  printContents = '';
  popupWin: any;


  constructor(public vServ: VisitlistService, public acServ: AccessiService, public ipServ: IpService) { }



  ngOnInit() {
    this.IdVisit = localStorage.getItem('idVisit');

    this.ipServ.getRemoteIp().then(data => {
      this.remoteIp = data;
      this.ipServ.getCheckRemoteIp(this.remoteIp.ip).then(d => {
        this.IdAzienda = d;
        this.LoadBadgeVisit();
      });
    });



  }


  LoadBadgeVisit() {
    this.vServ.getVisitatori(this.IdAzienda, this.IdVisit).then(data => {
      this.VisitDataBadge = data;
      console.log(this.VisitDataBadge);
      // *******
      this.CognomeNome = this.VisitDataBadge.cognome + ', ' + this.VisitDataBadge.nome;
      this.AziendaRifer = this.VisitDataBadge.descrizione_az;
      this.CodVisit =  (this.pad + this.VisitDataBadge.codvisit).slice(-this.pad.length);
      this.Operatore = this.VisitDataBadge.referente;
      this.LoadBadgeAccessi();
    });

  }

  LoadBadgeAccessi() {
    this.acServ.getAccessi(this.IdVisit, this.IdAzienda,).then(data => {
      this.AccessDatabadge = data;
      this.DataOraVisit = this.AccessDatabadge.entrata;
    });
  }

  BadgePrint(): void {
    let printContents, popupWin;
    printContents = document.getElementById('BadgeVisit').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=280,width=540');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>          
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close(); {
    }

  }


}


