

import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { OperatoriService } from '../../services/operatori.service';
import { BadgeService } from './../../services/badge.service';
import { VisitlistService } from './../../services/visitlist.service';
import { AccessiService } from './../../services/accessi.service';


@Component({
  selector: 'app-dlanagra',
  templateUrl: './dlanagra.component.html',
  styleUrls: ['./dlanagra.component.css']
})
export class DlanagraComponent implements OnInit {

  OperatData: any;
  BadgeData: any;
  VisitData: any;
  idAz: any;
  newVisit = 0;
  idAccesso: any;

  selectedvalue: string;
  selectedbadge: string;

  checked: boolean;

  italyTime: string;

  v = {codaz: 0, nome: '', cognome: '', email: '', descrizione_az: '', id: 0, referente: '', privacy: 'N', telefono: '', badgeass: ''};


  title = 'Nuovo Visitatore';

  constructor(public oServe: OperatoriService, public bServe: BadgeService, public vServe: VisitlistService, public acServe: AccessiService,
              public dialogRef: MatDialogRef<DlanagraComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public snackBar: MatSnackBar) {
    this.idAz = localStorage.getItem('codaz');
    this.newVisit = data.newVis;
    this.italyTime = this.calculateTime();
   }

  ngOnInit() {
    this.LoadComboOpertat(this.idAz);
    this.LoadComboBadge(this.idAz);
    if (this.newVisit !== 0) {
      this.LoadDataVisitor(this.idAz, this.newVisit);
      this.title = 'Modifica Visitatore';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  LoadComboOpertat(id) {
    this.oServe.getAllOperatori(id).then(data => {
      this.OperatData = data;
    });
  }

  LoadComboBadge(id) {
    this.bServe.getFreeBadge(id).then(data => {
      this.BadgeData = data;
    });
  }

  LoadDataVisitor(codaz, id) {
    this.vServe.getVisitatori(this.idAz, id).then(data => {
      this.VisitData = data;
      this.PushVisitData(this.VisitData);
    });
  }

  PushVisitData(vt) {
    this.v.codaz = vt.codaz;
    this.v.nome = vt.nome;
    this.v.cognome = vt.cognome;
    this.v.email = vt.email;
    this.v.descrizione_az = vt.descrizione_az;
    this.v.telefono = vt.telefono;
    this.v.id = vt.id;

    if (vt.privacy === 'S') {
      this.checked = true;
    } else {
      this.checked = false;
    }

    if (vt.id !== 0) {
      this.selectedvalue = vt.id;
    }

    this.bServe.getBadgeByVisit(this.newVisit).then(data => {
      this.v.badgeass = data.toString();
    });
  }

  SaveVisitor() {

    if (this.checked === false) { this.v.privacy = 'N';  } else { this.v.privacy = 'S'; }

    if (this.newVisit === 0) {
      this.v.codaz = this.idAz;
    }

    const d = JSON.stringify({
      nome: this.v.nome,
      cognome: this.v.cognome,
      codaz: this.v.codaz,
      email: this.v.email,
      descrizione_az: this.v.descrizione_az,
      telefono: this.v.telefono,
      id: this.selectedvalue,
      referente: this.v.referente,
      privacy: this.v.privacy
    });

    if (this.newVisit === 0) {
      this.createVisitor(d);
    } else {
      this.updateVisitor(d);
    }

    this.dialogRef.close();
  }

  updateVisitor(j) {
    this.bServe.getBadgeAccessiId(this.newVisit).then(d => {
      this.idAccesso = d;
      // *******
      const dj = JSON.stringify({
        numerobadge: this.selectedbadge
      });
      this.bServe.updateBadgeLiberi(this.idAccesso, dj).then(dt => {
        this.vServe.updateVisitatori(this.idAz, this.newVisit, j ).then(data => {
          this.newVisit = parseInt(data.toString(), 10);
          console.log(this.newVisit);
          this.snackBar.open('Aggiornamento anagrafica effettuato con successo!', 'OK', {
            duration: 3000
          });
        });
      });
    });
  }

  createVisitor(j) {
    this.vServe.createVisitatori(j).then(data => {
      this.newVisit = parseInt(data.toString(), 10);
      // creazione nuovo accesso visitatore
      const dAc = JSON.stringify({
        codvisit: this.newVisit,
        codaz: this.v.codaz,
        entrata: this.italyTime,
        idreferente: this.selectedvalue,
        numeroBadge: this.selectedbadge
      });
      this.acServe.createAccessi(dAc).then(dt => {
        if (dt !== 0) {
          this.snackBar.open('Registrazione accesso effettuato con successo!', 'OK', {
            duration: 3000
          });
        }
      });
    });
  }

  calculateTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }



}
