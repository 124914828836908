import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class OpzioniService {

  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(public http: HttpClient) { }

  getAttivazioneBadge(codaz) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'Opzioni/' + codaz + '/AttivaGestBadge').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }



}
