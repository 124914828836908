import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IpService } from './../services/ip.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  h = { visitCode: '' };
  vis: any;
  codaz: any;
  remoteIp: any;
  imgPersPath: string;
  imgPath: string;

  imgPersAz: string;

  constructor(private router: Router, public ipServ: IpService) { }

  ngOnInit() {
    this.imgPath = '../assets/img/logo.png';
    this.ipServ.getRemoteIp().then(data => {

      this.remoteIp = data;
      this.remoteIp = this.remoteIp.ip.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');

      this.ipServ.getCheckRemoteIp(this.remoteIp).then(d => {
        this.codaz = d;
        console.log(this.codaz);
        if (this.codaz !== 0) {
          this.imgPersPath = '../assets/img/' + this.codaz.toString() + '/logo.png';
          this.imgPersAz = '../assets/img/' + this.codaz.toString() + '/logo_gv.png';
        } else {

        }
      });
    });
  }



}
