import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class IpService {

  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  apiRemoteIp = 'http://ipv4.myexternalip.com/json';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(public http: HttpClient) { }


  getRemoteIp() {
    return new Promise(resolve => {
      this.http.get(this.apiRemoteIp).subscribe(data => {
        resolve(data);
          }, err => {
            console.log(err);
      });
    });
  }

  getCheckRemoteIp(ip) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'RemoteIp/' + ip).subscribe(data => {
        resolve(data);
          }, err => {
            console.log(err);
      });
    });
  }



  getAllIp(id) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'Ip/' + id).subscribe(data => {
        resolve(data);
          }, err => {
            console.log(err);
      });
    });
  }


  createIp(par) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'Ip/', par, this.httpOptions)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
      });
    });
  }

}
