import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

@Injectable()
export class BadgeService {

  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };


  constructor(public http: HttpClient) { }


   // ********************************
   getAllBadge() {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'Badge/').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getBadge(id) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'Badge/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getFreeBadge(id) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'BadgeLiberi/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getBadgeByVisit(id) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'BadgeByVisit/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getBadgeIdByNumber(codaz, numbadge) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'BadgeIdByNumber/' + codaz + '/' + numbadge).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }


  getBadgeAccessiId(id) {
    return new Promise(resolve => {
      this.http.get(this.apiUrl + 'AccessiGet/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }




  createBadge(par) {
    return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'Badge', par, this.httpOptions)
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
  }

  updateBadge(id, par) {
    return new Promise((resolve, reject) => {
        this.http.put(this.apiUrl + 'Badge/' + id, par, this.httpOptions)
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
  }

  updateBadgeLiberi(id, par) {
    return new Promise((resolve, reject) => {
      this.http.put(this.apiUrl + 'BadgeLiberi/' + id, par, this.httpOptions)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  deleteRuoli(id) {
    return new Promise(resolve => {
      this.http.delete(this.apiUrl + 'Badge/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
}
