import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';

import { Router } from '@angular/router';

import { IpService } from './../services/ip.service';
import { BadgeService} from '../services/badge.service';
import { AccessiService} from '../services/accessi.service';

@Component({
  selector: 'app-insertcode',
  templateUrl: './insertcode.component.html',
  styleUrls: ['./insertcode.component.css']
})
export class InsertcodeComponent implements OnInit {

  ic = { numbadge: '' };
  vis: any;
  codaz: any;
  remoteIp: any;
  imgPersPath: string;
  imgPath: string;

  imgPersAz: string;

  idNumBadge: any;
  visitbadge: any;

  italyTime: string;


  constructor(private router: Router, public ipServ: IpService, public bServ: BadgeService, public aServe: AccessiService,
              public snackBar: MatSnackBar) {
    this.italyTime = this.calculateTime();
  }

  ngOnInit() {
    this.imgPath = '../assets/img/logo.png';
    this.ipServ.getRemoteIp().then(data => {
      this.remoteIp = data;
      this.remoteIp = this.remoteIp.ip.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');
      this.ipServ.getCheckRemoteIp(this.remoteIp.ip).then(d => {
        this.codaz = d;
        if (this.codaz !== 0) {
          this.imgPersPath = '../assets/img/' + this.codaz.toString() + '/logo.png';
          this.imgPersAz = '../assets/img/' + this.codaz.toString() + '/logo_gv.png';
        } else {

        }
      });
    });
  }


  Confirm() {
    this.bServ.getBadgeIdByNumber(this.codaz, this.ic.numbadge).then(d => {
      this.idNumBadge = d;
    })
    // ******
    this.aServe.getAccessiBadge(this.codaz, this.idNumBadge).then(da => {
      this.visitbadge = da;
      // ******
      const ac = JSON.stringify({'Uscita': this.italyTime});
      this.aServe.updateAccessi(this.visitbadge.id, ac ).then(data => {
        if (data === 1) {
          this.snackBar.open('Uscita visitatore, confermata!', 'OK', {
            duration: 3000
          });
        }
        this.aServe.getAccessiBadge(this.codaz, this.idNumBadge).then(data => {
          this.visitbadge = data;

          localStorage.setItem('cognomeNome', this.visitbadge.cognomeNome);
          localStorage.setItem('entrata', this.visitbadge.entrata);
          localStorage.setItem('uscita', this.visitbadge.uscita);
          localStorage.setItem('referente', this.visitbadge.referente);
           this.router.navigateByUrl('/endvis');
        })
      });

    })

  }

  calculateTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }

}
