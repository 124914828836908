import { Component, ViewChild, OnInit,  } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';

import { AziendaService } from './../../../services/azienda.service';
import {Azienda} from '../../../model/azienda';

import { PassdataService } from '../../../services/passdata.service';




@Component({
  selector: 'app-admwdgriaz',
  templateUrl: './admwdgriaz.component.html',
  styleUrls: ['./admwdgriaz.component.css']
})
export class AdmwdgriazComponent implements OnInit {

  res: any;

  cols = ['ragsoc', 'pariva', 'buttons'];

  ds: MatTableDataSource<Azienda>;

  public az: Azienda[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public azServe: AziendaService,  private passDataServe: PassdataService) { }

  ngOnInit() {
    this.LoadData(this.az);
    this.ds = new MatTableDataSource(this.az);
    // ******
    this.ds.paginator = this.paginator;
    this.ds.sort = this.sort;
    // ******
    this.RefreshData();
  }

  LoadData(r) {
    this.azServe.getAllAzienda().then(data => {
      this.res = data;
      for (const a of this.res ) {
        r.push(this.AziendaData(a));
      }
    });
  }

  AziendaData(d): Azienda {
    return {
      codaz: d.Codaz,
      ragsoc: d.Ragsoc,
      pariva: d.Pariva,
      prefix: d.Prefix
    };
  }

  RefreshData() {
    this.azServe.getAllAzienda().then(data => {
      this.res = data;
      this.az = [];
      for (const a of this.res ) {
        this.az.push(this.AziendaData(a));
      }

      this.ds = new MatTableDataSource(this.az);
      this.ds.paginator = this.paginator;
      this.ds.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();         // Remove whitespace
    filterValue = filterValue.toLowerCase();  // Datasource defaults to lowercase matches
    this.ds.filter = filterValue;
  }

  EditCompany(id): void {
    this.passDataServe.sendMessage(id);
  }

}
