import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class VisitlistService {

  apiUrl = 'http://restserviceinto.mtsinformatica.com/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };


  constructor(public http: HttpClient) { }

// ********************************
getAllVisitatori(codaz: number) {
  return new Promise(resolve => {
    this.http.get(this.apiUrl + 'Visitatori/' + codaz).subscribe(data => {
      resolve(data);
    }, err => {
      console.log(err);
    });
  });
}

getVisitatori( codaz, id) {
   return new Promise(resolve => {
    this.http.get(this.apiUrl + 'Visitatori/' + codaz + '/' + id).subscribe(data => {
      resolve(data);
    }, err => {
      console.log(err);
    });
  });
}

createVisitatori(par) {
  return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'Visitatori', par, this.httpOptions)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
}

updateVisitatori(codaz: number, id: number, par) {
  return new Promise((resolve, reject) => {
      this.http.put(this.apiUrl + 'Visitatori/' + codaz.toString() + '/' + id.toString(), par, this.httpOptions)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
}

  deleteVisitatori(  id: number, codaz: number) {
    return new Promise(resolve => {
      this.http.delete(this.apiUrl + 'Visitatori/' + codaz.toString() + '/' + id.toString()).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }


}
