import { Component, OnInit } from '@angular/core';
import {Observable} from 'Rxjs/rx';

import { AccessiService } from './../../services/accessi.service';

@Component({
  selector: 'app-wdaccessi',
  templateUrl: './wdaccessi.component.html',
  styleUrls: ['./wdaccessi.component.css']
})
export class WdaccessiComponent implements OnInit {

  numVisit: any;
  dataOdierna: string;
  codaz = 3;

  constructor( public acServe: AccessiService) {
    this.dataOdierna = this.calculateTime();
   }

  ngOnInit() {
    this.LoadNumVisPresenti();
    this.RefreshData();
  }

  RefreshData() {
    Observable.interval(12000).subscribe(() => {
      this.LoadNumVisPresenti();
    });
  }


  LoadNumVisPresenti() {
    this.acServe.getVisitatoriPresenti(this.codaz).then(data => {
      this.numVisit = data;
    });
  }

  calculateTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime;
  }
}
