import { Component, OnInit } from '@angular/core';

import { IpService } from './../services/ip.service';

import { Router } from '@angular/router';
import { UserService } from './../services/user.service';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  yearNow = new Date();

  imgPersPath: string;
  imgPath: string;

  codaz: any;
  remoteIp: any;

  loginForm: FormGroup;
  loading = false;
  submitted = false;

  loginFormErrors: any;

  title = 'Area riservata - INTO 2.0';

  id: any;
  isLoginError: boolean;

  constructor(public ipServ: IpService, private userService: UserService, private router: Router, private formBuilder: FormBuilder,
    private snackBar: MatSnackBar) {
      this.loginFormErrors = {
        email   : {},
        password: {}
      };
  }
  ngOnInit() {
    this.imgPath = '../assets/img/logo.png';
    this.ipServ.getRemoteIp().then(data => {
      this.remoteIp = data;
      this.remoteIp = this.remoteIp.ip.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');
      this.remoteIp = this.remoteIp.toString().replace('.', '_');

      this.ipServ.getCheckRemoteIp(this.remoteIp).then(d => {
        this.codaz = d;
        if (this.codaz !== 0) {
          this.imgPersPath = '../assets/img/' + this.codaz.toString() + '/logo.png';
        } else {
          this.snackBar.open('Attenzione IP non trovato, INTO 2.0 sarà avviato modalità demo', 'OK', {
            duration: 5000
          });
         }
      });
    });

    this.isLoginError = false;
        this.loginForm = this.formBuilder.group({
          email   : ['', [Validators.required, Validators.email]],
          password: ['', Validators.required]
      });

     this.loginForm.valueChanges.subscribe(() => {
          this.onLoginFormValuesChanged();
      });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) { return; }
    this.loading = true;

    this.userService.userAuthentication(this.f.email.value, this.f.password.value, this.codaz).then(data => {
      this.id =  data;
      if (this.id !== 0) {
        this.userService.token(this.id).then(d => {
          localStorage.setItem('token', d.toString());
          localStorage.setItem('userid', this.id.toString());
          localStorage.setItem('codaz', this.codaz.toString());
          // ******
          this.router.navigateByUrl('/dashboard');
        });
      } else {
        this.snackBar.open('Utente inesistente', 'OK', {
          duration: 3000
        });
        this.router.navigateByUrl('/login');
        this.loading = false;
      }
    });
   }

   onLoginFormValuesChanged()  {
    for ( const field in this.loginFormErrors ) {
      if ( !this.loginFormErrors.hasOwnProperty(field) ) { continue; }
      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);

      if ( control && control.dirty && !control.valid ) {
        this.loginFormErrors[field] = control.errors; }
      }
    }

}
