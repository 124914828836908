import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatDialog} from '@angular/material';

import { VisitlistService } from './../../services/visitlist.service';

import { Visitatore } from './../../model/visitatore';

import { DlanagraComponent } from './../../dialog/dlanagra/dlanagra.component';

@Component({
  selector: 'app-wdanagra',
  templateUrl: './wdanagra.component.html',
  styleUrls: ['./wdanagra.component.css']
})
export class WdanagraComponent implements OnInit {

  codaz: any;

  res: any;

  cols = ['nome', 'cognome', 'email', 'descrizione_az',  'referente', 'buttons'];

  ds: MatTableDataSource<Visitatore>;

  public vs: Visitatore[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private vServ: VisitlistService, public dialog: MatDialog) {
    this.codaz = localStorage.getItem('codaz');
  }

  ngOnInit() {
    this.LoadData(this.vs);
    this.ds = new MatTableDataSource(this.vs);
    // ******
    this.ds.paginator = this.paginator;
    this.ds.sort = this.sort;
    // ******
    this.RefreshData();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();         // Remove whitespace
    filterValue = filterValue.toLowerCase();  // Datasource defaults to lowercase matches
    this.ds.filter = filterValue;
  }

  LoadData(c) {
    this.vServ.getAllVisitatori(this.codaz).then(data => {
      this.res = data;

      for (const rs of this.res ) {
        c.push(this.VisitData(rs));
      }
    });
  }

  VisitData(d): Visitatore {
    return {
      codvisit: d.Codvisit,
      codaz: d.Codaz,
      nome: d.Nome,
      cognome: d.Cognome,
      email: d.Email,
      descrizione_az: d.Descrizione_az,
      id : d.Id,
      privacy: d.Privacy,
      telefono: d.Telefono,
      referente: d.Referente
    };
  }

  RefreshData() {
    this.vServ.getAllVisitatori(this.codaz).then(data => {
      this.res = data;
      this.vs = [];
      for (const rs of this.res ) {
        this.vs.push(this.VisitData(rs));
      }
      this.ds = new MatTableDataSource(this.vs);
      this.ds.paginator = this.paginator;
      this.ds.sort = this.sort;
    });
  }

  NewVisitor(id) {
    this.openDialog(id);
  }


  openDialog(id): void {
    const dialogRef = this.dialog.open(DlanagraComponent, {
      width: '540px',
      data: { newVis: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.exitVisitor = result;
      /*
      if (this.exitVisitor === 'S') {
        const ac = JSON.stringify({'Uscita': this.italyTime});
        this.aServ.updateAccessi(id, ac ).then(data => {
          if (data === 1) {
            this.snackBar.open('Uscita visitatore, confermata!', 'OK', {
              duration: 3000
            });
            this.RefreshData();
          }
        });
      }
      */
    });
  }

}
