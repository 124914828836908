
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Ng2CsvModule } from 'ng2csv';

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { TopbarComponent } from './topbar/topbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { WdaccessiComponent } from './widget/wdaccessi/wdaccessi.component';
import { WdgriaccComponent, WdgriaccExitComponent } from './widget/wdgriacc/wdgriacc.component';
import { HomeComponent } from './home/home.component';
import { UserregComponent } from './userreg/userreg.component';
import { AnagraComponent } from './anagra/anagra.component';
import { WdanagraComponent } from './widget/wdanagra/wdanagra.component';
import { DlanagraComponent } from './dialog/dlanagra/dlanagra.component';

import { RoutingModuleModule } from './routing-module/routing-module.module';

import { AccessiService } from './services/accessi.service';
import { AziendaService } from './services/azienda.service';
import { IpService } from './services/ip.service';
import { OperatoriService } from './services/operatori.service';
import { RuoliService } from './services/ruoli.service';
import { UserService } from './services/user.service';
import { VisitlistService } from './services/visitlist.service';
import { BadgeService } from './services/badge.service';
import { StataccessiService } from './services/stataccessi.service';
import { OpzioniService } from './services/opzioni.service';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { OperatComponent } from './operat/operat.component';
import { WdoperatoriComponent } from './widget/wdoperatori/wdoperatori.component';
import { DloperatoriComponent } from './dialog/dloperatori/dloperatori.component';
import { LostpwdComponent } from './lostpwd/lostpwd.component';
import { RuoliComponent } from './ruoli/ruoli.component';
import { AziendaComponent } from './azienda/azienda.component';
import { WdruoliComponent } from './widget/wdruoli/wdruoli.component';
import { WdaziendaComponent } from './widget/wdazienda/wdazienda.component';
import { DlruoliComponent } from './dialog/dlruoli/dlruoli.component';
import { StataccessiComponent } from './stataccessi/stataccessi.component';
import { InsertcodeComponent } from './insertcode/insertcode.component';
import { EndvisitComponent } from './endvisit/endvisit.component';
import { PrintbadgeComponent } from './printbadge/printbadge.component';
import { AdmloginComponent } from './admin/admlogin/admlogin.component';
import { AdmnavtopComponent } from './admin/admnav/admnavtop/admnavtop.component';
import { AdmnavleftComponent } from './admin/admnav/admnavleft/admnavleft.component';
import { AdmdashComponent } from './admin/admdash/admdash.component';
import { AdmwdgriazComponent } from './admin/admwidget/admwdgriaz/admwdgriaz.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TopbarComponent,
    DashboardComponent,
    NavbarComponent,
    WdaccessiComponent,
    HomeComponent,
    UserregComponent,
    WdgriaccComponent, WdgriaccExitComponent, AnagraComponent, WdanagraComponent,
    DlanagraComponent, OperatComponent, WdoperatoriComponent, DloperatoriComponent, LostpwdComponent, RuoliComponent, AziendaComponent,
    WdruoliComponent, WdaziendaComponent, DlruoliComponent,  StataccessiComponent, InsertcodeComponent, EndvisitComponent, PrintbadgeComponent, AdmloginComponent, AdmnavtopComponent, AdmnavleftComponent, AdmdashComponent, AdmwdgriazComponent
  ],
  entryComponents: [WdgriaccExitComponent, DlanagraComponent, DlruoliComponent ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    RoutingModuleModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    RoutingModuleModule,
    FormsModule, ReactiveFormsModule, Ng2CsvModule
  ],
  providers: [
              AccessiService, AziendaService, IpService, OperatoriService, RuoliService,
              UserService, VisitlistService, BadgeService, StataccessiService, OpzioniService],
  bootstrap: [AppComponent]
})
export class AppModule { }
